import React, { useEffect, useRef } from "react";
import { Route, useHistory } from "react-router-dom";
import OpenSacForUnitGuid from "../pages/Faq/OpenSacForUnitGuid";
import Login from "../pages/Login";
import Manual from "../pages/Manual";
import Folder from "../pages/Folder";
import Faq from "../pages/Faq";
import Question from "~/pages/Faq/Question";
import Categories from "~/pages/Faq/Categories";
import { track, fetchCompany } from "~/services/track";
import Monitor from "~/pages/Monitor/Index";
import MonitorListDates from "~/pages/Monitor/MonitorListDates";
import InspectionSchedule from "~/pages/Inspection";
import InspectionSign from "~/pages/Inspection/Sign";
import AttachmentsInpections from "~/pages/Inspection/attachmentsInpections";
import SelectScheduleProvider from "~/pages/selectScheduleProvider";
import Finishes from "~/pages/Finishes";
import Custom from "~/pages/Custom";
import AproveProject from "~/pages/Custom/aproveProject";
import ProjectArchitect from "~/pages/Custom/projectArchitect";
import ProjectApproved from "~/pages/Custom/projectApproved";
import Guarantees from "~/pages/Guarantees";
import Suppliers from "~/pages/supplier";
import UnitMaintenances from "~/pages/Maintenances";
import UnitMaintenance from "~/pages/Maintenances/UnitMaintenace";
import Sac from "~/components/Sac";
import UnitSac from "~/components/Sac/Sac";

export default function Routes() {
    const history = useHistory()
    const visitedRef = useRef(false)
    useEffect(() => {
        if (history.location.pathname && history.location.pathname !== "/" && !visitedRef.current) {
            const [token, ...rest] = history.location.pathname.split("/").filter(Boolean)
            visitedRef.current = true
            fetchCompany(token).then(() => {
                track("ABRIU_APP", { path: rest.join("/") })
            })
        }
    }, [history.location.pathname])
    return (
        <>
            <Route exact path="/" component={Login} />
            <Route exact path="/:token" component={Manual} />
            <Route exact path="/assistencia/:nickName" component={OpenSacForUnitGuid} />
            <Route exact path="/assistencia/:nickName/sac/:sacGuid" component={OpenSacForUnitGuid} />
            <Route exact path="/:token/faq/:categoryGuid" component={Faq} />
            <Route exact path="/:token/question/:categoryGuid/:questionGuid" component={Question} />
            <Route exact path="/:token/categories" component={Categories} />
            <Route exact path="/:token/folder/:guid" component={Folder} />
            <Route exact path="/:token/assistencia" component={Sac} />
            <Route exact path="/:token/assistencia/chamado/:sacGuid" component={UnitSac} />
            <Route exact path="/:token/workMonitor/" component={Monitor} />
            <Route exact path="/:token/manutencoes" component={UnitMaintenances} />
            <Route exact path="/:token/manutencoes/:task_id" component={UnitMaintenance} />
            <Route exact path="/:token/workMonitor/MonitorListDates" component={MonitorListDates} />
            <Route exact path="/:token/inspection/schedule/:guid" component={InspectionSchedule} />
            <Route exact path="/:token/inspection/schedule/:guid/:type" component={InspectionSchedule} />
            <Route exact path="/:token/inspection/sign/:guid" component={InspectionSign} />
            <Route exact path="/:token/inspection/attachments" component={AttachmentsInpections} />
            <Route exact path="/:token/finishes/:guid" component={Finishes} />
            <Route exact path="/:token/customizacoes" component={Custom} />
            <Route exact path="/:token/customizacoes/list_project_send" component={ProjectArchitect} />
            <Route exact path="/:token/customizacoes/accept_project/:id" component={AproveProject} />
            <Route exact path="/:token/customizacoes/approved_project" component={ProjectApproved} />
            <Route exact path="/:token/sac/schedule/:guid" component={SelectScheduleProvider} />
            <Route exact path="/:token/showUnitGuarantees" component={Guarantees} />
            <Route exact path="/:token/suppliers" component={Suppliers} />
        </>
    );
}
