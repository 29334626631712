import axios from "axios";
import api_url from "./api_url";

const api = axios.create({
    baseURL: api_url,
});


api.interceptors.request.use(request => {
    // verificar se tem um token salvo para o token na url
    const predefined = request.headers?.['x-pwd']
    if (predefined) {
        return request
    }
    for (const u of request.url.split("/")) {
        const pwd = localStorage.getItem(`TOKEN-PWD-${u}`)
        if (pwd) {
            request.headers['x-pwd'] = pwd
            break
        }
    }

    return request
})

api.interceptors.response.use(response => {
    if (response?.headers?.['x-authenticated']) {
        const token = response.headers?.['x-token']
        const pwd = response.config?.headers?.['x-pwd']
        localStorage.setItem(`TOKEN-PWD-${token}`, pwd)
    }
    return response
}, (error) => {
    const { response } = error
    if (response?.status === 401) {
        const token = response.headers?.['x-token']
        if (response?.headers?.['x-must-authenticate'] === 'true') {
            const sp = new URLSearchParams()
            sp.append('auth_unit', token)
            sp.append('return_to', window.location.pathname)
            window.location.href = `/?${sp.toString()}`
        }  else if (response?.headers?.['x-invalid-password'] === 'true') {
            localStorage.removeItem(`TOKEN-PWD-${token}`)
        }
    }
    return Promise.reject(error)
})
    
export default api;
