import React, { useEffect, useState } from "react";
import "./Sac.sass";
import { Container, Col } from "react-bootstrap";
import { Emergencia } from "~/components/Icons";
import api from "~/services/api";
import { renderIconSvg } from "~/services/utils";
import { Typography } from "@material-ui/core";
import Loading from "../loading";
import FBButton from "../Button/FBButton";
import {ModalHeader} from "../Layout";


const style = { fill: "rgb(20, 81, 152)" }

export default ({guidUnit, push, token, setSuggestions, setConstructionSystem, setGuarantees, guidBuilding, previous, notEmergencyScreen }) => {

    const [listConstructionSystemCustom, setListConstructionSystemCustom] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function getCsBuilding() {
            setLoading(true)
            const result = await api.get(`v1/services_request/custom_cards/${guidBuilding}`);

            if (result?.data?.construction_systems?.length) {
                setListConstructionSystemCustom(result.data.construction_systems)
            } else {
                setListConstructionSystemCustom([])
            }
            setLoading(false)
        }
        getCsBuilding();
    }, [guidBuilding]);

    const selectType = async (type, guid, key) => {
        try {
            const { data } = await api.get(`/v1/questions/${token || guidUnit}/suggestions?type=${type || key}`)

            setConstructionSystem({ type, guid, key });

            if (data.length) {
                setSuggestions(data)
                push("suggestions-screen");
            } else {

                const params = [];

                type && params.push("key=" + type);
                key && params.push("name=" + key);

                const { data } = await api.get(`/v1/units/${token || guidUnit}/expired-guarantees` + ((params.length || "") && "?" + params.join("&")))

                if (data?.result?.length) {
                    setGuarantees(data.result)
                    push("expired-guarantees")
                } else {
                    push("form-screen")
                }

            }
        } catch (err) {
            console.error(err)
            setConstructionSystem({ type, guid, key });
            push("form-screen");
        }
    };

    return (
        <Container className="container-options" style={{ padding: 0 }}>
            <ModalHeader title="Classifique seu problema" previous={previous} />

            <Col>
                {loading ? <Loading /> :

                    listConstructionSystemCustom.length ?
                        listConstructionSystemCustom.map(cs => <>
                            <div className="options-cards" onClick={() => selectType(undefined, cs.guid, cs.key)} >
                                {renderIconSvg({ name: cs.custom_default, style })}
                                <Typography style={{ overflowWrap: "anywhere" }} >{cs.name}</Typography>
                            </div>
                        </>)
                        : (
                            <>
                                <div className="options-cards" onClick={() => selectType("elétrica")}>
                                    {renderIconSvg({
                                        name: "luz",
                                        style
                                    })}
                                    <Typography style={{ overflowWrap: "anywhere" }}>Instalações Elétricas</Typography>
                                </div>
                                <div className="options-cards" onClick={() => selectType("hidráulica")}>
                                    {renderIconSvg({
                                        name: "torneira",
                                        style
                                    })}
                                    <Typography style={{ overflowWrap: "anywhere" }}>Instalações Hidráulicas</Typography>
                                </div>
                                <div className="options-cards" onClick={() => selectType("revestimentos")}>
                                    {renderIconSvg({
                                        name: "parede",
                                        style
                                    })}
                                    <Typography style={{ overflowWrap: "anywhere" }}>Revestimento de piso, parede e teto</Typography>
                                </div>
                                <div className="options-cards" onClick={() => selectType("esquadrias")}>
                                    {renderIconSvg({
                                        name: "janela",
                                        style
                                    })}
                                    <Typography style={{ overflowWrap: "anywhere" }}>Esquadria de porta, janela e vidro</Typography>
                                </div>
                                <div className="options-cards" onClick={() => selectType("pintura")}>
                                    {renderIconSvg({
                                        name: "pincel",
                                        style
                                    })}
                                    <Typography style={{ overflowWrap: "anywhere" }}>Pintura</Typography>
                                </div>
                                <div className="options-cards" onClick={() => selectType("infiltrações")}>
                                    {renderIconSvg({
                                        name: "pingo",
                                        style
                                    })}
                                    <Typography style={{ overflowWrap: "anywhere" }}>Infiltrações</Typography>
                                </div>
                                <div className="options-cards" onClick={() => selectType("churrasqueira")}>
                                    {renderIconSvg({
                                        name: "churras",
                                        style
                                    })}
                                    <Typography style={{ overflowWrap: "anywhere" }}>Churrasqueira</Typography>
                                </div>
                                <div className="options-cards" onClick={() => selectType("fissuras")}>
                                    {renderIconSvg({
                                        name: "crack",
                                        style
                                    })}
                                    <Typography style={{ overflowWrap: "anywhere" }}>Fissuras</Typography>
                                </div>
                                <div className="options-cards" onClick={() => selectType("outros")}>
                                    {renderIconSvg({ name: "outros", style })}
                                    <Typography style={{ overflowWrap: "anywhere" }}>Outros</Typography>
                                </div>
                            </>
                        )
                }

                {!loading && !notEmergencyScreen &&
            <div
                className="options-cards"
                onClick={() => push("emergency-screen")}
            >
                <Emergencia style={style} />
                <Typography style={{ overflowWrap: "anywhere" }}>Emergência</Typography>
            </div>
                }
            </Col>
            {previous &&
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
            <FBButton
                style={{ width: "100px", marginRight: "10px" }}
                variant="outlined"
                onClick={() => previous()}
            >
            Voltar
            </FBButton>
        </div>
            }
        </Container>
    );
};
