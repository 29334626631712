import React, { useState, useEffect, useRef } from "react";
import "./Sac.sass";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { Button, Container } from "react-bootstrap";
import { Pdf } from "~/components/Icons";

import { CenterSpinnerButton } from "~/components/Spinner";
import CanvasDraw from "react-canvas-draw";
import api from "~/services/api";
import axios from "axios";
import ic_calendar from "../../assets/folder_icons/calendar.svg";
import { randomValue } from "~/services/utils";
import styled from "styled-components";
import FBButton from '~/components/Button/FBButton';
import documentImage from "../../assets/document.png"
import {useParams} from "react-router-dom";
const PSignatureChatBox = styled.div`
  background-color: white;
  max-Width: 50%;
  margin: 10px auto 10px auto;
  box-shadow: 0px 0px 4px rgba(8, 22, 48, 0.1);
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px 10px 10px 10px;
  padding: 5px;
  text-align: center;
  @media (max-width: 999px) {
    max-width: 50%;
  }
`
const FBButtonSchedule = styled(FBButton)`
  margin: 10px 0px 0px 0px;
  font-weight: 300;
  font-size: 12px;
  max-Width: 90px;
  max-height: 38px;
  line-Height: 20px;
  text-transform: unset;
`

const sacStatus = {
    changeBuildingSystem: 0,
    pendentAsk: 1,
    scheduleProfessionals: 2,
    finished: 4,
    cancel: 5,
    awaitingSignature: 6,
    signedByClient: 7,
    freeSchedule: 8,
}

export default ({ nickName, push, guid_conversation, closeModal }) => {
    const messagesEndRef = useRef(null);
    const signatureCanvasRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [serviceRequest, setServiceRequest] = useState("");
    const [showSignature, setShowSignature] = useState(false);

    const saveSignatureUnit = useRef(false);
    const { token } = useParams()

    async function getMessages() {
        try {
            setLoading(true);

            let response
            if(token){
                response = await api.get(`v1/units/${token}/services_request/${guid_conversation}`);
            }else{
                response = await api.get(`v1/services_request/${guid_conversation}`);
            }

            setServiceRequest(response.data);

            setTimeout(() => {
                const modal = document.querySelector(".modal.show");
                modal && modal.scrollTo(0, modal.scrollHeight);
            }, 500);

            setLoading(false);
        } catch (e) {
            setLoading(false);
            toast.error("Não foi possível localizar as mensagens da solicitação.");
        }
    }

    useEffect(() => {
        getMessages();
    }, [guid_conversation]);

    const shouldRequestSignature = serviceRequest?.status === sacStatus.awaitingSignature || serviceRequest?.custom_status?.default_status === "ASKED_SIGNATURE"

    useEffect(() => {

        if (guid_conversation) {
            let iId = setInterval(async () => {
                if (serviceRequest) {
                    let result
                    if(token){
                        result = await api.get(`v1/units/${token}/services_request/${guid_conversation}/lastMessage`,
                            { params: { guidMessage: serviceRequest.service_request_messages[(serviceRequest.service_request_messages.length) - 1].guid } });
                    }else{
                        result = await api.get(`v1/services_request/${serviceRequest.guid}/lastMessage`,
                            { params: { guidMessage: serviceRequest.service_request_messages[(serviceRequest.service_request_messages.length) - 1].guid } });
                    }

                    if (result.data === false) {
                        getMessages();
                    }
                }

            }, 5000);

            return () => {
                clearInterval(iId)
            }
        }

    }, [guid_conversation, serviceRequest]);

    const cancelSignature = () => {
        if (signatureCanvasRef.current) {
            signatureCanvasRef.current.clear();
        }
        setShowSignature(false);
    };

    async function onClickLinkOpenPage(metadata) {

        setTimeout(() => {
            const modal = document.querySelector(".modal.show");
            modal && modal.scrollTo(0, modal.scrollHeight);
        }, 500);

        if (metadata.type === "ScheduleProvider" || metadata.type === "Survey") {
            try {
                setLoading(true);

                const response = await api.get(metadata.validationUrl);

                if (response.status !== 200) {
                    toast.error(response.data.message)
                } else {
                    setTimeout(() => window.open(metadata.link, '_blank'))
                }

            } catch (err) {
                toast.error(err.response?.data?.message || 'Não foi possível localizar a disponibilidade');
            }

            setLoading(false);
        }

    }

    const saveSignature = async () => {

        if (saveSignatureUnit.current) {
            return
        }

        saveSignatureUnit.current = true;

        if (signatureCanvasRef.current.lines.length > 0) {
            signatureCanvasRef.current.canvas.drawing.toBlob(async (data) => {
                setLoading(true);

                const fileType = data.type;
                let uploadFile;

                //blob-to-file
                data.lastModifiedDate = new Date();
                data.name = `signature_${guid_conversation}.png`;

                try {
                    try {
                        const result = await api.get(`/v1/units/${token}/services_request/attachments/upload?sac_guid=${guid_conversation}&extension=${fileType}&t=${randomValue()}`);

                        let options = {
                            headers: {
                                "Content-Type": fileType,
                            },
                        };

                        await axios.create().put(result.data.uploadUrl, data, options);
                        uploadFile = result.data.resultUrl;
                        setServiceRequest({ ...serviceRequest, signature_url: uploadFile })
                        setLoading(false);
                    } catch (error) {
                        console.error(error);
                        Sentry.captureException(error)
                        toast.error(`Não foi possível fazer o upload da image!`);
                        setLoading(false);
                        return;
                    }

                    const body = {
                        signature_url: uploadFile,
                        unit_guid: serviceRequest.unit.guid,
                    };

                    await api.put(`/v1/units/${token}/services_request/${serviceRequest.guid}`, body);
                    cancelSignature();
                    closeModal && closeModal();
                    setLoading(false);
                    toast.success("Assinatura salva com sucesso.");
                } catch (e) {
                    Sentry.captureException(e)
                    console.error(e);
                    setLoading(false);
                    toast.error("Não foi possível enviar sua solicitação.");
                } finally {
                    saveSignatureUnit.current = false;
                }
            });

        } else {
            toast.error("A assinatura não pode estar em branco!");
        }
    };

    function renderAttachment(attachment_url) {
        let typeData = "default"

        if (attachment_url.includes(".jpeg") ||
            attachment_url.includes(".jpg") ||
            attachment_url.includes(".png")) {
            typeData = "image"
        }

        if (attachment_url.includes(".pdf")) {
            typeData = "pdf"
        }

        if (attachment_url.includes(".mp4") ||
            attachment_url.includes(".mov") ||
            attachment_url.includes(".avi") ||
            attachment_url.includes(".webm") ||
            attachment_url.includes(".quicktime")) {
            typeData = "video"
        }

        return (
            <li className="file-message" style={{ height: "150px" }} >

                {typeData === "image" && (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={attachment_url}
                    >
                        <img src={attachment_url} alt="imagem" />
                    </a>
                )}

                {typeData === "pdf" && (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={attachment_url}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <Pdf />
                    </a>
                )}

                {typeData === "video" && (
                    <video controls>
                        <source src={attachment_url} type="video/mp4" />
                    </video>
                )}

                {typeData === "default" && (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={attachment_url}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <img style={{ height: "100px" }} src={documentImage} alt="imagem" />
                    </a>
                )}

            </li>
        )

    }

    return (
        <Container className="container-conversation">
            {!nickName && <div>
                <div className="text-introduction">
                    <span onClick={() => push([])} className="icon-back" />
                    <h1>Assistência Técnica</h1>
                </div>

                <div className="schedule">
                    <Button className="button-mid" onClick={() => push("schedules-screen")}>
                        <img alt="caledario" src={ic_calendar} />
                    </Button>
                    <p className="schedule-text" onClick={() => push("schedules-screen")}><b>Agenda de Prestadores</b></p>
                </div>

            </div>
            }

            {!nickName && <hr />}

            {!showSignature && (
                <div className="body-chat-box">
                    <div id="scroll-chat-box">
                        {loading ? (
                            <CenterSpinnerButton color={"#000"} />
                        ) : (
                            serviceRequest &&
                            serviceRequest.service_request_messages.map((message, i) => {
                                const { text, attachment_url, name, attachments, audio, link_metadata } = message;
                                return (
                                    <ul
                                        key={i}
                                        className={
                                            message.system ? "system-messages" : message.user_guid ? "received-messages" : "sent-messages"
                                        }
                                    >
                                        <div>
                                            {!message.system && name && (<h6> {name === "adm" ? "Sistema" : name}</h6>)}

                                            <li>
                                                <p
                                                    style={{
                                                        fontStyle: message.has_previous_message && "italic",
                                                        color: message.has_previous_message ? "rgba(0, 0, 0, 70%)" : "rgba(0, 0, 0, 100%)"
                                                    }}
                                                >
                                                    {text}
                                                    {audio && (
                                                        <>
                                                            <br />
                                                            <audio preload="metadata" style={{ marginTop: 10, padding: "0 10px", maxWidth: '100%' }} controls src={audio} />
                                                            <br />
                                                        </>
                                                    )}
                                                </p>
                                                {link_metadata && (JSON.parse(link_metadata)).label && (JSON.parse(link_metadata)).link &&
                                                    <FBButtonSchedule
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => onClickLinkOpenPage(JSON.parse(link_metadata))}
                                                        style={{ marginTop: "10px" }}
                                                        disabled={loading || serviceRequest.status === sacStatus.finished}
                                                    >
                                                        {(JSON.parse(message.link_metadata)).label}
                                                    </FBButtonSchedule>
                                                }
                                            </li>


                                            {message.system ? (
                                                <>
                                                    {attachments && attachments.length > 0 &&
                                                        attachments.map(({ attachment_url }) =>
                                                            <PSignatureChatBox key={attachment_url}>
                                                                <img
                                                                    style={{ width: "100%" }}
                                                                    src={attachment_url}
                                                                    alt="Assinatura"
                                                                />
                                                            </PSignatureChatBox>
                                                        )}
                                                </>
                                            ) : (
                                                <>
                                                    {attachments && attachments.length ?
                                                        attachments.filter(({ attachment_url }) => attachment_url).map(({ attachment_url }) => renderAttachment(attachment_url)) :
                                                        attachment_url && renderAttachment(attachment_url)}
                                                </>
                                            )}

                                        </div>
                                    </ul>
                                );
                            })
                        )}
                        <div ref={messagesEndRef} />
                    </div>
                </div>
            )}

            <div>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                    }}
                >
                    {showSignature && (
                        <div>
                            <FBButton
                                style={{ marginBottom: 10, marginRight: 7, width: 100 }}
                                variant="outlined"
                                onClick={() => signatureCanvasRef.current.undo()}
                            >
                                Desfazer
                            </FBButton>

                            <FBButton
                                style={{ marginBottom: 10, width: 100 }}
                                disabled={loading}
                                color='primary'
                                onClick={() => signatureCanvasRef.current.clear()}
                            >
                                Limpar
                            </FBButton>
                        </div>
                    )}
                </div>
                {showSignature && (
                    <CanvasDraw
                        style={{
                            width: "100%",
                            position: "relative",
                        }}
                        className="canvasDraw"
                        brushRadius={1}
                        lazyRadius={1}
                        ref={(canvasDraw) => (signatureCanvasRef.current = canvasDraw)}
                    />
                )}
            </div>
            <div className="footer-chat-box">
                {shouldRequestSignature ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {showSignature &&
                            <FBButton
                                style={{ width: "100px", marginRight: "10px" }}
                                variant="outlined"
                                onClick={cancelSignature}
                            >
                                Voltar
                            </FBButton>
                        }

                        <FBButton
                            style={{ width: showSignature ? "150px" : "100px" }}
                            disabled={loading}
                            color='primary'
                            onClick={async () => { showSignature ? await saveSignature() : setShowSignature(true) }}
                        >
                            {showSignature ? "Salvar Assinatura" : "Assinar"}
                        </FBButton>
                    </div>
                ) : (serviceRequest.signature_url && shouldRequestSignature) ? (
                    <>
                        <div className="signature-wrapper">
                            <div>Sua assinatura:</div>
                            <img alt="assinatura" src={serviceRequest.signature_url} />
                        </div>
                        <br />
                    </>
                ) : null}

                {!showSignature && (
                    <FBButton
                        style={{ width: "100px" }}
                        disabled={loading || serviceRequest.status === sacStatus.finished}
                        {...shouldRequestSignature && { variant: "outlined" }}
                        color='primary'
                        onClick={() => push("form-screen")}
                    >
                        Responder
                    </FBButton>
                )}
            </div>
        </Container>
    );
};
