import React, { useEffect, useRef, useState } from "react";
import "./Sac.sass";
import { Container } from "react-bootstrap";
import FBButton from "../Button/FBButton";
import { Tooltip, Typography } from "@material-ui/core";
import styled from "styled-components";
import { ScrollBarStyle } from "~/pages/Custom";
import { ButtonLink } from "~/utils/ButtonLink";
import { ModalHeader } from "../Layout";

const DivScrool = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    padding: 5px;
    align-Items: start !important;
    overflow-X: auto;
    background-color: #EAF1FB;

    margin-bottom: 10px !important;
    max-height: calc(100vh - 210px); 

    @media (max-width: 999px) {
        max-height: calc(100vh - 150px); 
    }

    ${ScrollBarStyle}
`

export default ({ push, warningOpeningSac, setAcceptedOpeningTerm}) => {

    const [downScroll, setDownScroll] = useState(true)
    const [showInfoTooltip, setShowInfoTooltip] = useState(false)
    const divRefConversation = useRef(null);

    const handleScroll = () => {
        if (divRefConversation.current) {
            setDownScroll(divRefConversation.current.scrollTop + divRefConversation.current.clientHeight <= divRefConversation.current.scrollHeight - 30);
            setShowInfoTooltip(false)
        }
    };

    useEffect(() => {
        handleScroll();

        if (divRefConversation.current) {
            divRefConversation.current.addEventListener('scroll', handleScroll);
            divRefConversation.current.addEventListener('touchmove', handleScroll);

            return () => {
                divRefConversation.current.removeEventListener('scroll', handleScroll);
                divRefConversation.current.removeEventListener('touchmove', handleScroll);
            };
        }

    }, []);

    const handleSubmit = () => {
        setDownScroll(true)
        setShowInfoTooltip(false)
        setAcceptedOpeningTerm(warningOpeningSac)
        push("form-client");
    }

    function closeModal() {
        setDownScroll(true)
        setShowInfoTooltip(false)
        setAcceptedOpeningTerm(null)
        push([])
    }

    return (
        <>
            <Container>
                <ModalHeader title="Antes de abrir um chamado" previous={closeModal} />

                <DivScrool
                    ref={divRefConversation}
                    onScroll={handleScroll}
                >
                    <Typography style={{ padding: "15px 0", whiteSpace: 'break-spaces', wordBreak: 'break-word' }} > {warningOpeningSac}</Typography>
                </DivScrool>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <FBButton
                        style={{ width: "140px", marginRight: "10px" }}
                        variant="outlined"
                        onClick={closeModal}
                    >
                        Voltar
                    </FBButton>

                    <Tooltip open={showInfoTooltip} title={downScroll ? "Leia todo o termo" : ""}>
                        <ButtonLink onClick={() => setShowInfoTooltip(true)}>
                            <FBButton
                                disabled={downScroll}
                                style={{ width: "140px" }}
                                color='primary'
                                onClick={handleSubmit}
                            >
                                Estou de acordo
                            </FBButton>
                        </ButtonLink>
                    </Tooltip>
                </div>

            </Container>
        </>
    )
}
