import React from "react";

import GlobalStyle from "./styles/global";
import "./styles/icons-guide.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch } from "react-router-dom";
// import "video-react/dist/video-react.css";

import { ToastContainer, Slide } from "react-toastify";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./store";
import Routes from "./routes";
import history from "./services/history";
import { initTracking } from "./services/track";

import "~/pages/Home/Home.sass"
import { StyleSheetManager } from "styled-components";
import theme from "./contexts/theme";
import { ThemeProvider } from "@material-ui/core";

initTracking()

function App() {
    return (
        <ThemeProvider theme={theme}>
            <StyleSheetManager disableCSSOMInjection={true}>
                <Provider store={store}>
                    <Router history={history}>
                        <Switch>
                            <Routes />
                        </Switch>
                        <GlobalStyle />
                        <ToastContainer autoClose={3000} draggable transition={Slide} />
                    </Router>
                </Provider>
            </StyleSheetManager>
        </ThemeProvider>
    );
}

export default App;
