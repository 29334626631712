import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "../Login/Login.sass";
import Logo from "~/assets/imgs/common/logo.svg";
import { useParams } from "react-router-dom";
import { Box, Grid, Hidden, Typography } from "@material-ui/core";
import styled from "styled-components";
import BackIndex from "~/components/Navbar/BackIndex";
import { Wizard, Steps, Step } from "react-albus";
import FormClientSacForGuid from "~/components/Sac/FormClientSacForGuid";
import ServicesRequest from "~/components/Sac/ServicesRequest";
import FormBuildingSacForGuid from "~/components/Sac/FormBuildingSacForGuid";
import FormScreen from "~/components/Sac/FormScreen";
import SuggestionsScreen from "~/components/Sac/SuggestionsScreen";
import GuaranteesScreen from "~/components/Sac/GuaranteesScreen";
import logoSecondary from "../../assets/logosecondary.png";
import { ScrollBarStyle } from "../Custom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import theme from "../../contexts/theme";
import imgBackground from "../../assets/loginbg.jpg";
import { FormProvider, useForm } from "react-hook-form";
import api from "~/services/api";
import ConversationScreen from "~/components/Sac/ConversationScreen";

const LeftGrid = styled(Grid)`
    color: #fff;
    background-size: cover;
    height: 100%;
    width: 55%;
`;

const TotalGrid = styled(Grid)`
    ${ScrollBarStyle}
`

const UbuntuTypo = styled(Typography)`
    font-family: ${theme.typography.secondaryFontFamily};
    font-weight: 500;
`;

const useStyles = makeStyles((theme) => ({
    parentGrid: {
        height: "100%",
        width: "100%",
        overflow: "auto",
        [theme.breakpoints.up("sm")]:
        {
            width: "45%",
        },
    },
    contentBox: {
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        padding: theme.spacing(3),
        [theme.breakpoints.up("sm")]:
        {
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
        },
    },
}));

const DivBody = styled.div`
    background-color: white;
    border-radius: 6px;
    padding: 5px;
    border: 1px solid rgba(0,0,0,15%);
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`

export default function OpenSacForUnitGuid() {
    const classes = useStyles();
    const history = useHistory();
    const { nickName } = useParams();
    const { sacGuid } = useParams();
    const [logoCompany, setLogoCompany] = useState(undefined);
    const [listBuilding, setListBuilding] = useState([]);
    const [construction_system, setConstructionSystem] = useState({ type: undefined, guid: undefined, key: undefined });
    const [suggestions, setSuggestions] = useState(null);
    const [guarantees, setGuarantees] = useState(null);
    const [guid_conversation, setGuidConversation] = useState(undefined);
    const endRef = useRef(null);

    const methods = useForm({
        defaultValues: {
            company: undefined,
            guidBuilding: undefined,
            guidUnit: undefined,
            identify: undefined,
            client_name: undefined,
            client_document: undefined,
            client_email: undefined,
            client_phone: undefined,
        }
    })

    const { register, formState: { errors }, handleSubmit, setFocus, setValue, getValues } = methods

    useEffect(() => {

        async function laodData() {
            try {

                const { data } = await api.get(`v1/companies/nick/${nickName}/sac/${sacGuid}`);

                setValue("company", data.companie.id)
                setLogoCompany(data.companie.image_url)

                if (sacGuid) {
                    setValue("guidUnit", data.unitGuid)
                    setGuidConversation(sacGuid)
                }

                if (data.buildings.length) {
                    setListBuilding(data.buildings.map(v => ({ value: v.guid, label: v.name })))
                } else {
                    toast.error("Erro ao carregar, tente novamente mais tarde");
                    history.push("/");
                }

                setTimeout(() => {
                    if (endRef.current) {
                        endRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                    }
                }, 800);

            } catch (e) {
                toast.error("Link expirado ou existe.");
                history.push("/");
            }
        }

        laodData();
    }, [nickName, history, sacGuid]);

    return (
        <Grid container style={{ height: "100%" }} alignContent="center">
            <Hidden smDown={true}>
                <LeftGrid
                    xlDown={true}
                    container
                    item
                    justifyContent="center"
                    alignContent="flex-start"
                    style={{ background: `url("${imgBackground}")` }}
                >
                    <div
                        style={{
                            background: "rgba(6, 15, 32, 0.9)",
                            height: "100%",
                        }}
                    >
                        <Box
                            p={5}
                            pb={0}
                            style={{ height: "50%" }}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                        >
                            <Box>
                                <img alt='logo fastbuilt' src={logoSecondary} />
                            </Box>

                            <div>
                                <UbuntuTypo variant="h2">
                                    A melhor solução para seus empreendimentos.
                                </UbuntuTypo>
                                <UbuntuTypo variant="h4" style={{ fontWeight: 300, display: "flex" }}>
                                    Use a tecnologia a seu favor, use
                                    {" "}&nbsp;
                                    <Typography style={{ fontWeight: "bold" }} variant="h4"> FastBuilt</Typography>

                                </UbuntuTypo>
                            </div>
                        </Box>
                    </div>
                </LeftGrid>
            </Hidden>

            <TotalGrid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.parentGrid}
            >
                <Grid container item xl={15}>
                    <Box className={classes.contentBox} textAlign="center">

                        <div style={{ width: "100%" }}>
                            <img style={{ maxHeight: "200px", maxWidth: "250px", marginBottom: "10px", borderRadius: "6px" }} alt="Logo" src={logoCompany || Logo} />
                        </div>

                        <DivBody>

                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <BackIndex link={`/`} label={"Acessar unidade por QRCode"} />
                            </div>

                            <FormProvider {...methods}>
                                <Wizard >
                                    <Steps>
                                        <Step
                                            id="form-building"
                                            render={({ push }) => (
                                                <FormBuildingSacForGuid
                                                    push={push}
                                                    register={register}
                                                    handleSubmit={handleSubmit}
                                                    listBuilding={listBuilding}
                                                    getValues={getValues}
                                                    guid_conversation={guid_conversation}
                                                />
                                            )}
                                        />

                                        <Step
                                            id="form-client"
                                            render={({ push, previous }) => (
                                                <FormClientSacForGuid
                                                    push={push}
                                                    previous={previous}
                                                    register={register}
                                                    handleSubmit={handleSubmit}
                                                    setFocus={setFocus}
                                                    errors={errors}
                                                />
                                            )}
                                        />

                                        <Step
                                            id="options-screen"
                                            render={({ push, previous }) => (
                                                <ServicesRequest
                                                    push={push}
                                                    previous={previous}
                                                    nickName={nickName}
                                                    notEmergencyScreen
                                                    guidBuilding={getValues("guidBuilding")}
                                                    guidUnit={getValues("guidUnit")}
                                                    constructionSystem={construction_system}
                                                    setConstructionSystem={setConstructionSystem}
                                                    setGuarantees={setGuarantees}
                                                    setSuggestions={setSuggestions}
                                                />
                                            )}
                                        />

                                        <Step
                                            id="expired-guarantees"
                                            render={({ push }) => (
                                                <GuaranteesScreen
                                                    guarantees={guarantees}
                                                    suggestions={suggestions}
                                                    push={push}
                                                />
                                            )}
                                        />

                                        <Step
                                            id="suggestions-screen"
                                            render={({ push, previous }) => (
                                                <SuggestionsScreen
                                                    push={push}
                                                    previous={previous}
                                                    guidUnit={getValues("guidUnit")}
                                                    constructionSystem={construction_system}
                                                    suggestions={suggestions}
                                                    setGuarantees={setGuarantees}
                                                />
                                            )}
                                        />

                                        <Step
                                            id="form-screen"
                                            render={({ push, previous }) => (
                                                <FormScreen
                                                    push={push}
                                                    previous={previous}
                                                    handleSubmit={handleSubmit}
                                                    construction_system={construction_system}
                                                    guidUnit={getValues("guidUnit")}
                                                    name={getValues("client_name")}
                                                    phone={getValues("client_phone")}
                                                    email={getValues("client_email")}
                                                    documentNumber={getValues("client_document")}
                                                    identify={getValues("identify")}
                                                    guid_conversation={guid_conversation}
                                                    nickName
                                                />
                                            )}
                                        />

                                        <Step
                                            id="conversation-screen"
                                            render={({ push }) => (
                                                <ConversationScreen
                                                    push={push}
                                                    guid_conversation={guid_conversation}
                                                    nickName
                                                />
                                            )}
                                        />

                                    </Steps>
                                </Wizard>
                            </FormProvider>
                        </DivBody>

                    </Box>
                    <div ref={endRef} />
                </Grid>
            </TotalGrid>
        </Grid>
    );
}
